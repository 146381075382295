<template>
  <section :class="$customSectionClasses(props.cfgs)">
    <div class="materials-root">
      <img
        src="~/assets/img/svg/figures/opportunitesBlock/pattern.svg"
        alt="pattern"
        class="patternImg"
      >
      <div class="container">
        <div class="all-items-list">
          <ServicesList
            :title="values.title"
            :services="values.services"
          />
          <MaterialsList :materials="values.materials" />
        </div>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import type { IBlockDefaultProps } from '#sitis/internal/models/common';
import type { SectionTypes } from '~/plugins/customSectionClasses';

const { $customSectionClasses } = useNuxtApp();

export type MaterialItem = {
  image: number;
  title: string;
}

export type ServicesItem = {
  image: number;
  title: string;
  link: string;
}

type MaterialsListBlockType = Omit<IBlockDefaultProps, 'values' | 'cfgs'> & {
  values: {
    materials: MaterialItem[],
    services: ServicesItem[],
    title: string
  },
  cfgs: SectionTypes;
};

const props = defineProps<MaterialsListBlockType>();
</script>

<style scoped lang="scss">
.all-items-list {
  display: flex;
  gap: 65px;
}

.materials-root {
  padding-bottom: 150px;
  position: relative;
}

.patternImg {
  position: absolute;
  bottom: 0;
  left: 15px;
}

@media (max-width: 1024px) {
  .all-items-list {
    flex-direction: column;
    gap: 50px;
  }

  .patternImg {
    display: none;
  }
}

@media (max-width: 640px) {
  .materials-root {
    padding-bottom: 40px;
  }
}
</style>
